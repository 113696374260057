export function getOptimisedImage(image, images) {
  return images.nodes.find(({fluid: { originalName }}) => image.indexOf(originalName) > -1);
}

export function dataWithImages(data, image) {
  return {
    ...data,
    optimisedImage: image
  };
}
